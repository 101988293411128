.Container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: attr(data-width) px;
	height: attr(data-height) px;
}

.AnimatedBackground {
	background-repeat: no-repeat;
	background-position: center;
	background-size: 80px;
	width: 100%;
	min-height: 80px;
}